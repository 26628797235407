import { useTokenCookies } from "~/composables/auth";

export default defineNuxtRouteMiddleware(() => {
  const { refreshTokenCookie } = useTokenCookies();
  const refreshToken = refreshTokenCookie.value;

  if (!refreshToken) {
    return navigateTo("/login");
  }

  const payload = parseJwt(refreshToken);

  if (!payload?.is_staff) {
    return navigateTo("/login");
  }
});
